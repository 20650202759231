import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import {
  CONTENT_TYPE_JOB_POST,
  IJobPost,
  IJobPostContentAssociation,
  IJobPostContentItem,
  IPublishedJobPost,
  isJobPost,
  JobPostStatus,
} from '@employer/app/models/job-post.model'
import { CONTENT_TYPE_JOB_PROFILE, IJobProfile, IJobProfileContentItem, isJobProfile } from '@employer/app/models/job-profile.model'
import { AutocompleteType } from '@employer/app/services/autocomplete/autocomplete.model'
import { AutocompleteService } from '@employer/app/services/autocomplete/autocomplete.service'
import { JobPostContentService } from '@employer/app/services/job-post/job-post-content.service'
import { JobPostService } from '@employer/app/services/job-post/job-post.service'
import { JobProfileContentService } from '@employer/app/services/job-profile/job-profile-content.service'
import { JobProfileService } from '@employer/app/services/job-profile/job-profile-published.service'
import { EmployerNavigationService } from '@employer/app/services/navigation.service'
import * as fromReducers from '@employer/app/store/reducers'
import { selectors } from '@employer/app/store/selectors'
import { DEFAULT_LANGUAGE_CODE, IContentAssociation } from '@engineering11/content-web'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { deepCopy, isDeepEqual, isNotNil } from '@engineering11/utility'
import { E11ErrorHandlerService, E11Logger } from '@engineering11/web-api-error'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { Store } from '@ngrx/store'
import { combineLatest, EMPTY, from, Observable, of } from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators'
import { getCurrentUser, JobProfileStatus, NotificationTranslateService } from 'shared-lib'
import { defaultAssociation } from '../job.util'
import { CustomJobGenerationRequest, JobPostGenerationService } from '../services/job-post-generation.service'
import { getJobItemValidation } from '../services/job-validation.util'
import { UserHiringTeamConfigStore } from '@employer/app/modules/account/stores/user-hiring-team-config.store'

export interface JobProfileState {
  jobProfiles: IJobProfileContentItem[]
  jobProfile: IJobProfileContentItem | null
  jobPosts: IJobPostContentItem[]
  jobPost: IJobPostContentItem | null
  jobForm: null | Partial<IJobProfile>
  currentType: ContentItemType | null
  isPublishing: boolean

  publishedDocument: IPublishedJobPost | null
}

const defaultState: JobProfileState = {
  jobProfiles: [],
  jobProfile: null,
  jobPosts: [],
  jobPost: null,
  jobForm: {},
  currentType: null,
  isPublishing: false,

  publishedDocument: null,
}

interface ContentItemGetArgs {
  contentId: string
  customerKey?: string
  languageCode?: string
  snapshotId?: string
  associations: Map<string, IContentAssociation>
}

interface JobProfileArg {
  action: JobProfileContentAction
  user: IEmployerUser
  jobProfile: IJobProfileContentItem
}

interface JobPostArg {
  action: JobPostContentAction
  user: IEmployerUser
  jobPost: IJobPostContentItem
}

export interface JobPostFromTemplateRequest {
  contentId: string // The profile contentId to seed the post from
  languageCode?: string
  associations?: Map<string, IContentAssociation>
  alteredName?: string
  snapshotId?: string // The published snapshotId to seed the post from
}

export enum JobProfileContentAction {
  saveProfile = 'saveProfile',
  publishProfile = 'publishProfile',
  deleteProfile = 'deleteProfile',
  rePublishProfile = 'rePublishProfile',
  reactivateTemplate = 'reactivateTemplate',
  deactivateProfile = 'deactivateProfile',
}

export enum JobPostContentAction {
  savePost = 'savePost',
  publishPost = 'publishPost',
  deletePost = 'deletePost',
}

export type JobContentItem = IJobProfileContentItem | IJobPostContentItem
export type ContentItemType = 'jobProfile' | 'jobPost'

@Injectable({
  providedIn: 'root',
})
export class JobProfileStore extends ComponentStore<JobProfileState> {
  user$ = this.store.select(getCurrentUser).pipe(filter(isNotNil))
  customerKey$ = this.store.pipe(selectors.getCustomerKey$).pipe(filter(isNotNil))

  constructor(
    private router: Router,
    private store: Store<fromReducers.user.State>,
    private jobProfileContentService: JobProfileContentService,
    private jobPostContentService: JobPostContentService,
    private jobPostService: JobPostService,
    private jobProfileService: JobProfileService,
    private errorHandler: E11ErrorHandlerService,
    private navigationService: EmployerNavigationService,
    private jobPostGenerationService: JobPostGenerationService,
    private logger: E11Logger,
    private notificationService: NotificationTranslateService,
    private autoCompleteService: AutocompleteService,
    private userHiringTeamConfigStore: UserHiringTeamConfigStore
  ) {
    super(defaultState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly jobForm$ = this.select(({ jobForm }) => jobForm)
  readonly jobProfile$ = this.select(({ jobProfile }) => jobProfile)
  readonly jobPost$ = this.select(({ jobPost }) => jobPost)
  private readonly currentType$ = this.select(({ currentType }) => currentType)
  readonly isPublishing$ = this.select(({ isPublishing }) => isPublishing)
  readonly isPublished$ = this.select(({ publishedDocument }) => !!publishedDocument)
  readonly publishedJobPost$ = this.select(({ publishedDocument }) => publishedDocument)

  /**
   * Selector to pick jobProfile or jobPost state based on a given content type
   *
   * Note: If no content type was given it will select the state that is not null, if both are null
   * Will default to jobPost
   *
   */
  readonly jobItem$ = (type?: ContentItemType) =>
    this.select(this.jobProfile$, this.jobPost$, (jobProfile, jobPost) => {
      if (type === undefined) {
        return jobProfile ?? jobPost
      }
      return type === 'jobProfile' ? jobProfile : jobPost
    })

  readonly currentJobItem$ = this.select(this.currentType$, this.jobProfile$, this.jobPost$, (type, jobProfile, jobPost) => {
    return type === 'jobProfile' ? jobProfile : jobPost
  })

  private readonly currentContentId$ = this.select(this.currentJobItem$, item => item?.contentId)

  readonly editDisabled$ = this.jobItem$().pipe(
    map(item => item?.document),
    map(document => {
      if (!document) {
        return true
      }
      if (isJobProfile(document)) {
        return [JobProfileStatus.Closed, JobProfileStatus.Deleted].includes(document.status)
      }
      if (isJobPost(document)) {
        return [JobPostStatus.ClosedFilled, JobPostStatus.ClosedNotFilled, JobPostStatus.Deleted].includes(document.status)
      }
      this.logger.warn('We should only be working with job posts or profiles', document)
      return true
    })
  )

  readonly jobItemValidation$ = this.select(this.currentJobItem$, item => getJobItemValidation(item))
  readonly isJobPostValid$ = this.select(this.jobItemValidation$, validation => !Object.values(validation).some(item => !item))

  // UPDATERS/REDUCERS

  /**
   *
   * loadJobItem can dispatch action to set the state with a given ContentItem
   *
   * The content Item can be IJobPostContentItem or IJobProfileContentItem
   * It will check the document content type key to select either one
   * @param {JobContentItem} jobContentItem the data that is passed must be of IJobPostContentItem or IJobProfileContentItem
   * @throws Error Note: It will throw error if the give content item is now IJobPostContentItem or IJobProfileContentItem
   *
   */
  readonly loadJobItem = (jobContentItem: any) => {
    switch (jobContentItem?.document?.contentType) {
      case CONTENT_TYPE_JOB_POST:
        this.hydrateJobPost(jobContentItem as IJobPostContentItem)
        break
      case CONTENT_TYPE_JOB_PROFILE:
        this.hydrateJobProfile(jobContentItem as IJobProfileContentItem)
        break
      default:
        throw new Error('Incorrect Job Item content type')
    }
  }

  private readonly hydrateJobProfile = this.updater((state, jobProfile: IJobProfileContentItem) => ({
    ...state,
    jobProfile,
    currentType: 'jobProfile',
  }))

  private readonly hydrateJobPost = this.updater((state, jobPost: IJobPostContentItem) => ({
    ...state,
    jobPost,
    currentType: 'jobPost',
  }))

  private readonly setIsPublishing = this.updater((state, isPublishing: boolean) => ({ ...state, isPublishing }))
  private readonly setPublishedDocument = this.updater((state, publishedDocument: IPublishedJobPost | null) => ({
    ...state,
    publishedDocument,
  }))

  readonly clear = this.updater((state, value) => ({
    ...state,
    jobProfiles: [],
    jobProfile: null,
    jobPosts: [],
    jobPost: null,
  }))

  // EFFECTS

  readonly createJobPostFromTemplate = this.effect((request$: Observable<JobPostFromTemplateRequest>) =>
    request$.pipe(
      withLatestFrom(this.user$, this.userHiringTeamConfigStore.swimlaneConfig$),
      mergeMap(([request, user, config]) => {
        const associations = defaultAssociation(user, config)

        const defaultItem = {
          languageCode: DEFAULT_LANGUAGE_CODE,
          associations,
          ...request,
        }
        const customerKey$ = this.getCustomerKey$().pipe(take(1))
        const profileItem$ = from(this.jobProfileContentService.get(request.contentId, defaultItem.languageCode, request.snapshotId))
        return combineLatest([customerKey$, profileItem$]).pipe(
          tapResponse(
            async ([customerKey, profileItem]) => {
              const item = this.profileToPostContentItem(profileItem, customerKey, defaultItem)
              const ci = await this.jobPostContentService.savePost(user, item)
              this.navigationService.toJobPost(ci.contentId)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly createJobTemplateFromPost = this.effect((request$: Observable<JobPostFromTemplateRequest>) =>
    request$.pipe(
      withLatestFrom(this.user$),
      mergeMap(([request, user]) => {
        const associations = defaultAssociation(user)
        const defaultItem = {
          languageCode: DEFAULT_LANGUAGE_CODE,
          associations,
          ...request,
        }
        const customerKey$ = this.getCustomerKey$().pipe(take(1))
        const profileItem$ = from(this.jobPostContentService.get(request.contentId, defaultItem.languageCode, request.snapshotId))
        return combineLatest([customerKey$, profileItem$]).pipe(
          tapResponse(
            async ([customerKey, profileItem]) => {
              const item = this.postToProfileContentItem(profileItem, customerKey, defaultItem, request.alteredName!)
              this.logger.log('profileItem', item)
              await this.jobProfileContentService.saveProfile(user, item)
              this.notificationService.popNotification(saveSuccessNotification)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  /**
   * TODO: Extend to support profile
   */
  readonly generateJobPost = this.effect((request$: Observable<CustomJobGenerationRequest>) =>
    request$.pipe(
      withLatestFrom(this.user$),
      mergeMap(async ([request, user]) => {
        const generatedData = await this.jobPostGenerationService.generateCustom(request)
        const { jobDescription, qualifications, responsibilities, skills } = generatedData
        return this.createNewJobPostAndRoute({
          description: jobDescription,
          minimumQualifications: qualifications,
          responsibilities: this.formatNewlines(responsibilities),
          selectedSkills: skills,
          title: request.jobTitle,
          jobTitle: request.jobTitle,
          keywords: request.keywords,
        })
      })
    )
  )

  readonly createNewJobPostAndRoute = this.effect((payload$: Observable<Partial<IJobPost>>) =>
    payload$.pipe(
      withLatestFrom(this.user$, this.userHiringTeamConfigStore.swimlaneConfig$),
      mergeMap(([payload, user, swimlanes]) => {
        const associations = defaultAssociation(user, swimlanes) as Map<string, IJobPostContentAssociation>
        const document: IJobPost = {
          ...payload,
          status: JobPostStatus.Draft,
          customerKey: user.customerKey,
          contentType: CONTENT_TYPE_JOB_POST,
          languageCode: DEFAULT_LANGUAGE_CODE,
        }
        const itemToSave = { associations, document, customerKey: user.customerKey }

        document.selectedSkills?.forEach(skill => {
          this.autoCompleteService.add(user.customerKey, AutocompleteType.Skill, skill.name)
        })

        return from(
          this.jobPostContentService.savePost(user, {
            associations,
            document,
            customerKey: user.customerKey,
          })
        ).pipe(
          tapResponse(
            jobPostItem => {
              this.logger.log({ jobPostItem })
              this.loadJobItem(itemToSave)
              this.navigationService.toJobPost(jobPostItem.contentId)
            },
            (e: Error) => this.errorHandler.handleError(e)
          )
        )
      })
    )
  )

  readonly getJobPostFromProfile = this.effect((payload$: Observable<ContentItemGetArgs>) => {
    return payload$.pipe(
      mergeMap((payload: ContentItemGetArgs) =>
        this.getCustomerKey$().pipe(
          mergeMap((customerKey: string) => {
            return from(this.jobProfileContentService.get(payload.contentId, payload.languageCode, payload.snapshotId)).pipe(
              tap({
                next: contentItem => this.hydrateJobPost(this.profileToPostContentItem(contentItem, customerKey, payload)),
                error: e => this.errorHandler.handleError(e),
              }),
              catchError(e => {
                throw new Error(e)
              })
            )
          })
        )
      )
    )
  })

  readonly jobProfileAction = this.effect((payload$: Observable<JobProfileArg>) => {
    return payload$.pipe(
      mergeMap(payload =>
        from(this.jobProfileContentService[payload.action](payload.user, payload.jobProfile)).pipe(
          tap({
            next: (response: IJobProfileContentItem) => {
              if (payload.action === JobProfileContentAction.publishProfile) this.notificationService.popNotification(profilePublishingNotif)
              return this.hydrateJobProfile(response)
            },
            error: e => new Error(e),
          }),
          catchError(e => {
            throw new Error(e)
          })
        )
      )
    )
  })

  readonly jobPostAction = this.effect((payload$: Observable<JobPostArg>) => {
    return payload$.pipe(
      mergeMap(payload =>
        from(this.jobPostContentService[payload.action](payload.user, payload.jobPost)).pipe(
          tap({
            next: (response: IJobPostContentItem) => {
              if (payload.action === JobPostContentAction.publishPost) {
                this.notificationService.popNotification(postPublishingNotif)
              }
              return this.hydrateJobPost(response)
            },
            error: e => new Error(e),
          }),
          catchError(e => {
            throw new Error(e)
          })
        )
      )
    )
  })

  readonly listenIsPublishing = this.effect((_$: Observable<undefined>) =>
    combineLatest([this.currentContentId$, this.customerKey$]).pipe(
      distinctUntilChanged(isDeepEqual),
      switchMap(([contentId, customerKey]) => {
        const isPublishing$ = contentId ? this.jobPostContentService.isPublishing(contentId, customerKey) : of(false) // If contentId is undefined, we must not be publishing
        return isPublishing$.pipe(
          tapResponse(
            isPublishing => this.setIsPublishing(isPublishing),
            (err: Error) => this.errorHandler.handleError(err)
          )
        )
      })
    )
  )

  readonly listenPublished = this.effect((_$: Observable<undefined>) =>
    this.currentJobItem$.pipe(
      filter(isNotNil),
      distinctUntilKeyChanged('contentId'),
      switchMap((jobItem: IJobProfileContentItem | IJobPostContentItem) => {
        const { contentType, id } = jobItem.document
        const publishedDocumentService = contentType === 'jobProfile' ? this.jobProfileService : this.jobPostService
        const publishedDocument$ = publishedDocumentService.getValueChanges(id!) as Observable<IPublishedJobPost | undefined>
        return publishedDocument$.pipe(
          tapResponse(
            publishedDocument => {
              this.logger.log('JobProfileStore.listenPublished - setting document', { publishedDocument })
              return this.setPublishedDocument(publishedDocument ?? null)
            },
            (err: Error) => this.errorHandler.handleError(err)
          )
        )
      })
    )
  )

  // Helper reflectors
  readonly selectLatestProfileAnd = (action: JobProfileContentAction, user: IEmployerUser) =>
    this.jobProfile$.pipe(
      take(1),
      filter(isNotNil),
      tap({
        next: jobProfile => this.jobProfileAction({ action, user, jobProfile: deepCopy(jobProfile) }),
        error: e => new Error(e),
      })
    )

  readonly selectLatestPostAnd = (action: JobPostContentAction, user: IEmployerUser) =>
    this.jobPost$.pipe(
      take(1),
      filter(isNotNil),
      tap({
        next: jobPost => this.jobPostAction({ action, user, jobPost }),
        error: e => new Error(e),
      })
    )

  readonly selectLatestPost = () => this.jobPost$.pipe(take(1))

  private profileToPostContentItem(contentItem: IJobProfileContentItem, customerKey: string, payload: ContentItemGetArgs) {
    return {
      document: {
        ...contentItem.document!,
        contentType: CONTENT_TYPE_JOB_POST,
        id: undefined,
        contentId: undefined,
        status: JobPostStatus.Draft,
        customerKey,
      },
      associations: payload.associations,
      customerKey,
    } as IJobPostContentItem
  }

  private postToProfileContentItem(contentItem: IJobPostContentItem, customerKey: string, payload: ContentItemGetArgs, alteredName: string) {
    return {
      document: {
        ...contentItem.document!,
        contentType: CONTENT_TYPE_JOB_PROFILE,
        jobTitle: alteredName,
        title: alteredName,
        id: undefined,
        contentId: undefined,
        status: JobProfileStatus.Draft,
        customerKey,
      },
      associations: payload.associations,
      customerKey,
    } as IJobProfileContentItem
  }

  // Other state selectors
  private getCustomerKey$(): Observable<string> {
    return this.store.select(selectors.getCustomerKey).pipe(mergeMap(customerKey => (!!customerKey ? of(customerKey) : EMPTY)))
  }

  private formatNewlines(unformated: String) {
    return unformated.replace(/\n/g, '<br />')
  }
}

const postPublishingNotif: INotificationMessage = {
  title: 'Success!',
  message: 'Your Job Post is saved and publishing... This could take just a moment to completely update.',
  type: 'success',
}
const profilePublishingNotif: INotificationMessage = {
  title: 'Success!',
  message: 'Your Job Template is saved and publishing... This could take just a moment to completely update.',
  type: 'success',
}
const saveSuccessNotification: INotificationMessage = {
  title: 'Success!',
  message: 'Your new Job Template has been created! Remember, it is going to be in your Drafts.',
  type: 'success',
  autoClose: true,
}
