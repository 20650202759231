import { JobContentItem } from '../stores/job-profile.store'

export interface IJobItemValidation {
  jobTitle: boolean
  employmentType: boolean
  description: boolean
  minimumQualifications: boolean
  responsibilities: boolean
  employeeLocation: boolean
  locationAddress: boolean
}

export function getJobItemValidation(jobItem: JobContentItem | null): IJobItemValidation {
  if (!jobItem || !jobItem.document) {
    return {
      jobTitle: false,
      employmentType: false,
      description: false,
      minimumQualifications: false,
      responsibilities: false,
      employeeLocation: false,
      locationAddress: false,
    }
  }
  const { jobTitle, employeeLocation, employmentType, description, minimumQualifications, responsibilities, locationAddress } = jobItem.document
  return {
    jobTitle: !!jobTitle,
    employeeLocation: !!employeeLocation,
    employmentType: !!employmentType,
    description: !!description,
    minimumQualifications: !!minimumQualifications,
    responsibilities: !!responsibilities,
    locationAddress: !!locationAddress,
  }
}
