<!-- Profile dropdown -->
<div
  class="e11-z-50 e11-flex e11-justify-between e11-items-center e11-relative"
  (closeOnClickOutside)="dropDownOpen = false"
  *ngrxLet="currentUser$ as currentUser"
>
  <e11-avatar
    [imageUrl]="currentUser?.photoURL"
    [size]="'md'"
    [initials]="currentUser?.firstName!.charAt(0) + currentUser?.lastName!.charAt(0)"
    (click)="dropDownOpen = !dropDownOpen"
  ></e11-avatar>

  <div
    (click)="dropDownOpen = !dropDownOpen"
    [ngClass]="{
      'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
      'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
    }"
    class="e11-origin-top-right e11-absolute e11-right-0 e11-top-10 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="user-menu-button"
    tabindex="-1"
  >
    <a
      [routerLink]="[routes.ACCOUNT]"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-0"
    >
      {{ 'My Account' | translate }}
    </a>

    <ng-container *ngrxLet="userPermissions$ as permissions">
      <!-- Company manage permissions -->
      <ng-container *ngIf="permissions?.has(Permission.COMPANY_MANAGE)">
        <a
          [routerLink]="[routes.COMPANY_ACCOUNT]"
          class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-1"
        >
          {{ 'Company Settings' | translate }}
        </a>
      </ng-container>

      <!-- Company manage permissions -->
      <ng-container *ngIf="permissions?.has(CustomerPermissions.ViewClient)">
        <a
          [routerLink]="[routes.CLIENT_MANAGEMENT]"
          class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
          role="menuitem"
          tabindex="-1"
          id="user-menu-item-1"
        >
          {{ 'Client Management' | translate }}
        </a>
      </ng-container>
    </ng-container>

    <a
      href="#"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-2"
      (click)="signOut()"
    >
      {{ 'Sign out' | translate }}
    </a>
  </div>

  <span
    (click)="dropDownOpen = !dropDownOpen"
    *ngrxLet="customerName$ as customerName"
    class="e11-flex e11-items-center sm:e11-visible e11-pl-4 e11-py-2 e11-rounded-md e11-text-sm e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
  >
    <div class="e11-flex-col e11-min-w-[100px]">
      <p [ngClass]="!currentUser ? 'e11-mb-1' : 'e11-mb-0'" class="empty:e11-skeleton empty:e11-w-full">
        {{ currentUser?.firstName?.concat(' ') }}{{ currentUser?.lastName }}
      </p>
      <p class="e11-text-skin-light e11-mb-0 empty:e11-skeleton empty:e11-w-full">{{ customerName }}</p>
    </div>
    <span class="material-icons e11-ml-2">expand_more</span>
  </span>
</div>
