import { Component, Input, ViewChild } from '@angular/core'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'

@Component({
  selector: 'ui-candidate-share-modal',
  template: `
    <e11-modal
      #modalProfileShare
      [width]="'lg'"
      [showHeader]="true"
      [closer]="false"
      [showFooter]="false"
      [title]="'Share This Application' | translate"
    >
      <ng-template e11Template="Body">
        <ui-candidate-share-form
          [jobApplicationId]="jobApplicationId"
          [jobPostId]="jobPostId"
          (formSubmitted)="modalProfileShare?.close($event)"
        ></ui-candidate-share-form>
      </ng-template>
    </e11-modal>
  `,
})
export class UiCandidateShareModalComponent {
  @ViewChild('modalProfileShare') modalProfileShare!: E11ModalComponent
  @Input({ required: true }) jobPostId!: string
  @Input({ required: true }) jobApplicationId!: string

  openModal() {
    this.modalProfileShare?.open()
  }
}
