import { selectors } from '@admin/app/store/selectors'
import { Component, OnInit } from '@angular/core'
import { IEmployerUser } from '@cnect/user-shared'
import { Permission } from '@employer/app/config/permission.config'
import { ROUTES } from '@employer/app/services/navigation.service'
import { PermissionStore } from '@engineering11/access-web'
import { BaseCustomerConfigProvider, CustomerPermissions, CustomerService, CustomerStore } from '@engineering11/customer-web'
import { Store } from '@ngrx/store'
import { Observable, map } from 'rxjs'
import { OnLogOut } from 'shared-lib'

@Component({
  selector: 'profile-dropdown',
  templateUrl: 'profile-dropdown.component.html',
})
export class ProfileDropdownComponent implements OnInit {
  routes = ROUTES
  dropDownOpen = false
  Permission = Permission
  CustomerPermissions = CustomerPermissions
  userPermissions$ = this.permissionStore.userPermissions$
  currentUser$: Observable<IEmployerUser | null> = this.store.select(selectors.getCurrentUser)

  customerName$ = this.customerStore.currentCustomer$.pipe(map(c => c?.name))

  constructor(private store: Store, private permissionStore: PermissionStore, private customerStore: CustomerStore) {}

  ngOnInit() {}

  signOut() {
    this.store.dispatch(new OnLogOut())
  }
}
