import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { IEmployerUser } from '@cnect/user-shared'
import { CustomerService } from '@engineering11/customer-web'
import { LocalStorageService } from 'shared-lib'
import { IInterviewQuestion } from '../../interview-question.model'
import { Timestamp } from '@engineering11/web-api-firebase'

@Component({
  selector: 'ui-interview-question',
  templateUrl: './interview-question.component.html',
  styleUrls: ['./interview-question.component.scss'],
})
export class InterviewQuestionComponent implements OnInit {
  user?: IEmployerUser

  @Input() question?: Timestamp<IInterviewQuestion> | null

  @Output() editClicked = new EventEmitter()

  constructor(private localStorageService: LocalStorageService) {}

  ngOnInit(): void {}
}
