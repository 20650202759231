import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { JobApplicationViewsStore } from '@employer/app/modules/jobs/stores/job-application-views.store'
import { JobApplicationStore } from '@employer/app/modules/jobs/stores/job-application.store'
import { CandidateDetailTabs, CandidateNavigationSource, EmployerNavigationService } from '@employer/app/services/navigation.service'
import { isDeepEqual, isNotNil } from '@engineering11/utility'
import { Timestamp } from '@engineering11/web-api-firebase'
import { Observable, distinctUntilChanged, filter, map } from 'rxjs'
import { APPLICATION_STATE, ARCHIVED_STATES, CnectFeatures, ConfigStore, IJobPostApplication } from 'shared-lib'
import { UiCandidateShareModalComponent } from './ui-candidate-share-modal.component'

export enum CandidateActions {
  Preview = 'Preview',
  ViewDetails = 'View details',
  Feedback = 'Feedback',
  SendMessage = 'Send message',
  RequestInfo = 'Request info',
  Dialogue = 'Interview Session',
  Reactivate = 'Reactivate',
  ShareProfile = 'Share Profile',
  Reject = 'Reject',
}

const actionToTab: { [key in string]: CandidateDetailTabs } = {
  [CandidateActions.ViewDetails]: CandidateDetailTabs.Profile,
  [CandidateActions.Feedback]: CandidateDetailTabs.Feedback,
  [CandidateActions.RequestInfo]: CandidateDetailTabs.RequestMoreInfo,
  [CandidateActions.SendMessage]: CandidateDetailTabs.DirectMessage,
  [CandidateActions.Dialogue]: CandidateDetailTabs.Dialogues,
}

@Component({
  selector: 'ui-alg-card',
  templateUrl: './ui-alg-card.component.html',
  styleUrls: ['./ui-alg-card.component.scss'],
})
export class UiAlgCardComponent implements OnInit {
  @ViewChild('modalProfileShare') modalProfileShare!: UiCandidateShareModalComponent
  @Input() item?: Timestamp<IJobPostApplication> // Item comes from Algolia, so might not PERFECTLY reflect IJobPostApplication

  @Input() loading: boolean = false
  @Input() viewAll: boolean = false // Non swimlane view. All candidates are being viewed so we will use this to show candidate status (since the swimlane wont do that for us)
  @Output() candidateSelected = new EventEmitter<string>()
  @Output() changeApplicationStatusClicked = new EventEmitter<APPLICATION_STATE>()
  @Output() reactivateApplicationClicked = new EventEmitter()

  candidateActions = CandidateActions

  protected readonly APPLICATION_STATE = APPLICATION_STATE

  applicationStatusOverflowOptions = [
    APPLICATION_STATE.APPLIED,
    APPLICATION_STATE.SCREENED,
    APPLICATION_STATE.INTERVIEWING,
    APPLICATION_STATE.FINALIST,
    APPLICATION_STATE.OFFER_SENT,
    APPLICATION_STATE.HIRED,
    APPLICATION_STATE.NOT_INTERESTED,
  ]

  applicationViewed$?: Observable<boolean>
  lastApplicationViewed$: Observable<string | null> = this.jobApplicationViewsStore.lastApplicationViewed$
  isUpdatingApplication$?: Observable<boolean>

  features$: Observable<CnectFeatures | undefined> = this.configStore.features$
  actionOverflowOptions$ = this.features$.pipe(
    filter(isNotNil),
    distinctUntilChanged(isDeepEqual),
    map(features => this.getOverflowOptions(features))
  )

  constructor(
    private navigationService: EmployerNavigationService,
    private jobApplicationViewsStore: JobApplicationViewsStore,
    private jobApplicationStore: JobApplicationStore,
    private configStore: ConfigStore
  ) {}

  ngOnInit(): void {
    if (!this.item) return
    this.applicationViewed$ = this.jobApplicationViewsStore.applicationViewedForJob$(this.item.jobPostId, this.item.id)
    this.isUpdatingApplication$ = this.jobApplicationStore.isUpdatingApplicationStatus$(this.item.id)
  }

  candidateSelection() {
    this.candidateSelected.emit(this.item?.id)
  }

  candidateActionOverflowChoice(event: CandidateActions): CandidateActions {
    if (!this.item) {
      return event
    }

    switch (event) {
      case CandidateActions.Preview: {
        this.candidateSelected.emit(this.item.id)
        return event // Type checking to ensure switch is exhaustive
      }
      case CandidateActions.Reject: {
        this.changeApplicationStatusClicked.emit(APPLICATION_STATE.NOT_INTERESTED)
        return event
      }
      case CandidateActions.Reactivate: {
        this.reactivateApplicationClicked.emit()
        return event
      }
      case CandidateActions.ShareProfile: {
        this.modalProfileShare.openModal()
        return event
      }
      case CandidateActions.ViewDetails:
      case CandidateActions.SendMessage:
      case CandidateActions.RequestInfo:
      case CandidateActions.Feedback:
      case CandidateActions.Dialogue: {
        this.navigationService.setCandidateNavSource(CandidateNavigationSource.List)
        this.navigationService.toCandidateDetailPage(this.item.jobPostId, this.item.id, actionToTab[event])
        return event
      }
    }
  }

  changeApplicationStatus(status: APPLICATION_STATE) {
    this.changeApplicationStatusClicked.emit(status)
  }

  private getOverflowOptions(features: CnectFeatures) {
    const candidateActionOverflowOptions = [CandidateActions.Preview, CandidateActions.ViewDetails, CandidateActions.Feedback]
    const candidateActiveOptions = [CandidateActions.SendMessage, CandidateActions.RequestInfo, CandidateActions.Dialogue]

    if (!ARCHIVED_STATES.includes(this.item?.applicationState!)) candidateActionOverflowOptions.push(...candidateActiveOptions)
    if (features?.employerShareApplication) candidateActionOverflowOptions.push(CandidateActions.ShareProfile)
    return candidateActionOverflowOptions
  }
}
