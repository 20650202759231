<ui-candidate-share-modal *ngIf="item" #modalProfileShare [jobPostId]="item.jobPostId" [jobApplicationId]="item.id"></ui-candidate-share-modal>
<e11-card
  (click)="!loading && candidateSelection()"
  [isClickable]="!loading"
  [status]="loading ? false : (lastApplicationViewed$ | async) === item?.id"
  id="candidateId_{{ item?.id }}"
>
  <div [ngClass]="{ 'e11-mb-4': loading }" class="e11-w-full e11-flex e11-items-center e11-justify-between">
    <h3 *ngIf="!loading" class="e11-line-clamp-1 e11-mb-0 e11-text-base e11-text-skin-primary">{{ item?.firstName }} {{ item?.lastName }}</h3>

    <h4 *ngIf="loading" class="e11-h3-skeleton"></h4>
    <div class="e11-flex e11-items-start e11-justify-end">
      <e11-overflow
        #candidateActionOverflow
        (clickOptionEvent)="candidateActionOverflowChoice($any($event))"
        *ngIf="!loading"
        [closeOnMouseOut]="true"
        [menuMaxWidth]="160"
        [menuPosition]="'bottom left'"
        [options]="(actionOverflowOptions$ | async) ?? []"
      >
        <li
          *ngIf="!(item?.applicationState | isApplicationArchived)"
          (click)="candidateActionOverflow.clickOption(candidateActions.Reject, $event)"
          class="e11-py-1 e11-px-4 e11-text-sm hover:e11-bg-skin-grey/20 e11-text-skin-secondary e11-cursor-pointer e11-font-primary-medium"
          custom-option
        >
          {{ 'Reject' }}
        </li>
        <li
          *ngIf="item?.applicationState === APPLICATION_STATE.NOT_INTERESTED"
          (click)="candidateActionOverflow.clickOption(candidateActions.Reactivate, $event)"
          class="e11-py-1 e11-px-4 e11-text-sm hover:e11-bg-skin-grey/20 e11-text-skin-primary e11-cursor-pointer e11-font-primary-medium"
          custom-option
        >
          {{ 'Reactivate' }}
        </li>
      </e11-overflow>
      <div *ngIf="loading" class="e11-skeleton-empty !e11-w-5 e11-h-5"></div>
    </div>
  </div>
  <div class="e11-w-full e11-flex e11-items-center e11-mb-2">
    <div class="e11-mr-2 e11-chart-percentage-container e11-text-center">
      <e11-chart-percentage *ngIf="!loading" [data]="item?.jobMatchScore || 0" [imageUrl]="item?.photoURL" [size]="'80px'"></e11-chart-percentage>
      <div *ngIf="loading" class="e11-chart-percentage-skeleton e11-h-[68px] e11-w-[68px]"></div>
    </div>

    <div class="e11-grow">
      <div class="e11-w-full e11-flex">
        <div class="e11-grow">
          <!-- Rating -->
          <div *ngIf="!loading" class="e11-flex e11-items-center e11-h-4">
            <ui-candidate-stars [starRating]="item?.starRating || 0"></ui-candidate-stars>
            <span *ngIf="item?.firstImpression" class="material-icons-outlined e11-ml-2 e11-mb-1"> smart_display </span>
          </div>

          <p *ngIf="!loading && item?.address !== undefined" class="e11-text-xs e11-mb-0">
            {{ item?.address | addressFormat }}
          </p>

          <div *ngIf="!loading" class="e11-flex e11-items-center e11-h-5">
            <span class="e11-truncate e11-text-xs e11-mr-1"> {{ 'Applied on' | translate }}: {{ item?.appliedDate | date : 'shortDate' }} </span>
            <e11-overflow
              #applicationStatusActionOverflow
              *ngIf="!(item?.applicationState | isApplicationArchived)"
              (clickOptionEvent)="changeApplicationStatus($any($event))"
              [closeOnMouseOut]="true"
              [icon]="'expand_more'"
              [iconColor]="'primary'"
              [menuMaxWidth]="160"
              [menuPosition]="'bottom left'"
            >
              <li
                (click)="applicationStatusActionOverflow.clickOption(status, $event)"
                *ngFor="let status of applicationStatusOverflowOptions"
                class="e11-py-1 e11-px-4 e11-text-sm hover:e11-bg-skin-grey/20 e11-cursor-pointer e11-font-primary-medium"
                [ngClass]="{ 'e11-text-skin-secondary': status === APPLICATION_STATE.NOT_INTERESTED }"
                custom-option
              >
                {{ status | applicationState }}
              </li>
            </e11-overflow>

            <!-- Seen/Viewed? -->
            <ng-container *ngrxLet="applicationViewed$ as applicationViewed">
              <ng-container *ngrxLet="isUpdatingApplication$ as isUpdatingApplication">
                <span
                  *ngIf="!applicationViewed && !isUpdatingApplication"
                  class="material-icons md-14 e11-p-[3px] e11-text-skin-white e11-bg-skin-secondary e11-rounded-full"
                  [attr.title]="'You have not viewed this application' | translate"
                >
                  notifications_active
                </span>

                <e11-icon-loader classOverrides="e11-text-skin-primary" *ngIf="isUpdatingApplication"></e11-icon-loader>
              </ng-container>
            </ng-container>
          </div>
          <p class="e11-text-sm e11-font-primary-demibold" *ngIf="viewAll">
            {{ item?.applicationState || APPLICATION_STATE.APPLIED | applicationState }}
          </p>
          <p *ngIf="loading" class="e11-skeleton-empty e11-mb-2 !e11-w-[80%]"></p>
          <div *ngIf="loading" class="e11-flex e11-items-center">
            <p class="e11-skeleton-empty e11-mb-0 e11-mr-2 e11-w-full"></p>
            <div class="e11-skeleton-empty !e11-w-5 e11-h-5"></div>
          </div>

          <!-- CONFIRMATIONS -->

          <job-application-confirmations *ngIf="item?.candidateResponses" [responses]="item?.candidateResponses"> </job-application-confirmations>
        </div>
      </div>
    </div>
  </div>
</e11-card>
