import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { E11AccordionModule } from '@engineering11/ui-lib/e11-accordion'
import { E11AutocompleteModule } from '@engineering11/ui-lib/e11-autocomplete'
import { E11AvatarModule } from '@engineering11/ui-lib/e11-avatar'
import { E11BackdropModule } from '@engineering11/ui-lib/e11-backdrop'
import { E11BadgeModule } from '@engineering11/ui-lib/e11-badge'
import { E11ButtonModule } from '@engineering11/ui-lib/e11-button'
import { E11CardModule } from '@engineering11/ui-lib/e11-card'
import { E11ChartPercentageModule } from '@engineering11/ui-lib/e11-chart-percentage'
import { E11ChipModule } from '@engineering11/ui-lib/e11-chip'
import { E11ChipUserModule } from '@engineering11/ui-lib/e11-chip-user'
import { E11CloserModule } from '@engineering11/ui-lib/e11-closer'
import { E11ConfirmationModule } from '@engineering11/ui-lib/e11-confirmation'
import { E11DividerModule } from '@engineering11/ui-lib/e11-divider'
import { E11DropDownModule } from '@engineering11/ui-lib/e11-dropdown'
import { E11EmptyStateModule } from '@engineering11/ui-lib/e11-empty-state'
import { E11ErrorMessagesModule } from '@engineering11/ui-lib/e11-error-messages'
import { E11DynamicFormModule } from '@engineering11/ui-lib/e11-form-builder'
import { E11IconModule } from '@engineering11/ui-lib/e11-icon'
import { E11ImageCropperModuleModule } from '@engineering11/ui-lib/e11-image-cropper'
import { E11IndicatorModule } from '@engineering11/ui-lib/e11-indicator'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11InputCheckboxModule } from '@engineering11/ui-lib/e11-input-checkbox'
import { E11InputErrorsModule } from '@engineering11/ui-lib/e11-input-errors'
import { E11LoaderDotsModule } from '@engineering11/ui-lib/e11-loader-dots'
import { E11LoaderEllipsisModule } from '@engineering11/ui-lib/e11-loader-ellipsis'
import { E11LoaderLineModule } from '@engineering11/ui-lib/e11-loader-line'
import { E11LoaderSpinnerModule } from '@engineering11/ui-lib/e11-loader-spinner'
import { E11ModalModule } from '@engineering11/ui-lib/e11-modal'
import { E11MultiSelectModule } from '@engineering11/ui-lib/e11-multi-select'
import { E11NavExpanderModule } from '@engineering11/ui-lib/e11-nav-expander'
import { E11NavItemComponent, E11NavLeftModule } from '@engineering11/ui-lib/e11-nav-left'
import { E11OverflowModule } from '@engineering11/ui-lib/e11-overflow'
import { E11PanelModule } from '@engineering11/ui-lib/e11-panel'
import { E11PopoverModule } from '@engineering11/ui-lib/e11-popover'
import { E11ProfilePicModule } from '@engineering11/ui-lib/e11-profile-pic'
import { E11RadioInputModule } from '@engineering11/ui-lib/e11-radio-input'
import { E11SelectModule } from '@engineering11/ui-lib/e11-select'
import { E11SideOverModule } from '@engineering11/ui-lib/e11-side-over'
import { E11SidesheetModule } from '@engineering11/ui-lib/e11-sidesheet'
import { E11SwitchModule } from '@engineering11/ui-lib/e11-switch'
import { E11TabsModule } from '@engineering11/ui-lib/e11-tabs'
import { E11TabsOnlyModule } from '@engineering11/ui-lib/e11-tabs-only'
import { E11TagModule } from '@engineering11/ui-lib/e11-tag'
import { E11TextEditorModule } from '@engineering11/ui-lib/e11-text-editor'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { E11TitleBarModule } from '@engineering11/ui-lib/e11-title-bar'
import { E11TooltipModule } from '@engineering11/ui-lib/e11-tooltip'
import { E11SelectorModule } from '@engineering11/ui-lib/e11-selector'

const ImportExportModules = [
  // New E11 Modules
  E11AccordionModule,
  E11AvatarModule,
  E11BackdropModule,
  E11BadgeModule,
  E11ButtonModule,
  E11CardModule,
  E11ConfirmationModule,
  E11ChartPercentageModule,
  E11ChipModule,
  E11ChipUserModule,
  E11DividerModule,
  E11EmptyStateModule,
  E11InputModule,
  E11InputCheckboxModule,
  E11ModalModule,
  E11RadioInputModule,
  E11PanelModule,
  E11ProfilePicModule,
  E11TitleBarModule,
  E11SideOverModule,
  E11TextEditorModule,
  E11LoaderDotsModule,
  E11LoaderSpinnerModule,
  E11LoaderLineModule,
  E11IconModule,
  E11SidesheetModule,
  E11TooltipModule,
  E11ImageCropperModuleModule,
  E11MultiSelectModule,
  E11InputErrorsModule,
  E11SelectModule,
  E11SwitchModule,
  E11OverflowModule,
  E11TextareaModule,
  E11AutocompleteModule,
  E11PopoverModule,
  E11ErrorMessagesModule,
  E11TabsModule,
  E11DropDownModule,
  E11TagModule,
  E11LoaderEllipsisModule,
  E11NavExpanderModule,
  E11NavLeftModule,
  E11NavItemComponent,
  E11TabsOnlyModule,
  E11IndicatorModule,
  E11DynamicFormModule,
  E11CloserModule,
  E11SelectorModule,
]

@NgModule({
  declarations: [],
  imports: [CommonModule, ...ImportExportModules],
  exports: [...ImportExportModules],
})
export class UiComponentLibraryModule {}
