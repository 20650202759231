import { CustomerPermissions } from '@engineering11/customer-web'

export enum Permission {
  /** Currently, if you can list templates, you can create them */
  JOB_TEMPLATE_LIST = 'JOB_TEMPLATE_LIST',
  TEAM_MANAGE = 'TEAM_MANAGE',
  COMPANY_MANAGE = 'COMPANY_MANAGE',
  /**
   * Below permissions whether the user can access these features. Some individual items may still be
   * hidden based on other permissions
   */
  ApplicationsView = 'applications/view',
  PromotionsView = 'promotions/view',
  JobsView = 'jobs/view',
  CreateJob = 'jobs/create-job',
  ViewDispositionCodes = 'jobs/view-disposition-codes',
}
/**
 * All recognised permissions should be noted in this type
 */
export type AllPermissions = Permission | CustomerPermissions
