import { Injectable } from '@angular/core'
import { Timestamp } from '@engineering11/types'
import { E11NotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { compareKeyDesc } from '@engineering11/utility'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { NotificationHelper } from '@engineering11/web-ui-helpers'
import { ComponentStore } from '@ngrx/component-store'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { tapResponse } from '@ngrx/operators'
import { Observable, finalize, firstValueFrom, from, mergeMap, switchMap } from 'rxjs'
import { copyToClipboard } from 'shared-lib'
import { CreateJobApplicationTokenDTO, IJobApplicationToken } from './job-post-application-share.model'
import { JobPostApplicationShareService } from './job-post-application-share.service'

export interface IJobPostApplicationShareStore extends EntityState<Timestamp<IJobApplicationToken>> {
  tokensLoaded: boolean
  deletingTokenIds: string[]
  isPdfDownloading: boolean
}

type IJobPostAndApplicationIds = { jobPostId: string; jobApplicationId: string }

export const adapter: EntityAdapter<Timestamp<IJobApplicationToken>> = createEntityAdapter<Timestamp<IJobApplicationToken>>({
  selectId: share => share.id,
  sortComparer: compareKeyDesc('__createdAt'),
})
const selectors = adapter.getSelectors()

const initialState: IJobPostApplicationShareStore = adapter.getInitialState({
  tokensLoaded: false,
  deletingTokenIds: [],
  isPdfDownloading: false,
})

@Injectable({
  providedIn: 'root',
})
export class JobPostApplicationShareStore extends ComponentStore<IJobPostApplicationShareStore> {
  constructor(
    private jobPostApplicationService: JobPostApplicationShareService,
    private errorHandler: E11ErrorHandlerService,
    private notificationHelper: NotificationHelper
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)
  readonly jobPostShares$ = this.select(s => selectors.selectAll(s))
  readonly tokensLoaded$ = this.select(s => s.tokensLoaded)
  readonly deletingTokenIds$ = this.select(s => s.deletingTokenIds)
  readonly isPdfDownloading$ = this.select(s => s.isPdfDownloading)

  readonly shareTokensForJobApplicationShare$ = (jobApplicationId: string) =>
    this.select(this.jobPostShares$, tokens => tokens.filter(t => t.jobApplicationId === jobApplicationId))

  // EFFECTS
  readonly onGetAllByJobPost = this.effect((jobPostId$: Observable<string>) =>
    jobPostId$.pipe(
      switchMap(jobPostId => {
        this.setLoading()
        return this.jobPostApplicationService.getAllByJobPost(jobPostId).pipe(
          tapResponse(
            shares => this.onSharesFetched(shares),
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onGetAllByJobApplication = this.effect((jobIds$: Observable<IJobPostAndApplicationIds>) =>
    jobIds$.pipe(
      switchMap(({ jobPostId, jobApplicationId }) => {
        this.setLoading()
        return this.jobPostApplicationService.getAllByJobApplication(jobPostId, jobApplicationId).pipe(
          tapResponse(
            shares => this.onSharesFetched(shares),
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onCreateShare = this.effect((createReq$: Observable<CreateJobApplicationTokenDTO>) =>
    createReq$.pipe(
      mergeMap(req => {
        return this.jobPostApplicationService.create(req).pipe(
          tapResponse(
            res => {
              copyToClipboard(res.link!)
              this.onCreated(res)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly onDeleteShare = this.effect((token$: Observable<IJobApplicationToken>) =>
    token$.pipe(
      mergeMap(token => {
        this.addDeletingId(token.id)
        return this.jobPostApplicationService.delete(token.token).pipe(
          tapResponse(
            res => {
              this.onDeleted(token.id)
              this.removeDeletingId(token.id)
            },
            (error: Error) => this.errorHandler.handleError(error)
          )
        )
      })
    )
  )

  readonly quickSharePdf = this.effect((req$: Observable<CreateJobApplicationTokenDTO>) =>
    req$.pipe(
      switchMap(req => {
        this.setPdfDownloading(true)
        return from(this.#createAndDownloadPdf(req)).pipe(
          tapResponse(
            res =>
              this.notificationHelper.popNotification({
                title: 'Success',
                message: `Your PDF has been generated!`,
                type: 'success',
                autoClose: true,
                dismissOnRouteChange: true,
              }),
            (err: Error) => this.errorHandler.handleError(err, { alertUser: true })
          ),
          finalize(() => this.setPdfDownloading(false))
        )
      })
    )
  )

  async #createAndDownloadPdf(req: CreateJobApplicationTokenDTO) {
    const pdfToken = await firstValueFrom(this.jobPostApplicationService.create(req))
    this.addShareToken(pdfToken)
    return this.jobPostApplicationService.downloadPDF(pdfToken.token, pdfToken.jobApplicationId)
  }

  // UPDATERS

  private readonly addShareToken = this.updater((s, shareToken: Timestamp<IJobApplicationToken>) =>
    adapter.addOne(shareToken, { ...s, createTokenLoading: false })
  )

  private readonly onSharesFetched = this.updater((state, shares: Timestamp<IJobApplicationToken>[]) =>
    adapter.setAll(shares, { ...state, tokensLoaded: true })
  )

  private readonly setLoading = this.updater(state => ({ ...state, tokensLoaded: false }))

  private readonly addDeletingId = this.updater((state, tokenId: string) => ({
    ...state,
    deletingTokenIds: [tokenId, ...state.deletingTokenIds],
  }))

  private readonly removeDeletingId = this.updater((state, tokenId: string) => ({
    ...state,
    deletingTokenIds: state.deletingTokenIds.filter(id => id !== tokenId),
  }))

  private readonly onCreated = this.updater((state, share: Timestamp<IJobApplicationToken>) => adapter.addOne(share, { ...state }))

  private readonly onDeleted = this.updater((state, id: string) => adapter.removeOne(id, state))

  private readonly setPdfDownloading = this.updater((state, isPdfDownloading: boolean) => ({ ...state, isPdfDownloading }))
}

const acceptSuccessMessage: E11NotificationMessage = {
  title: 'Success',
  message: `You have added a new profile share!`,
  type: 'success',
  autoClose: true,
  dismissOnRouteChange: true,
}
