import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CustomerModule as SDKCustomerModule } from '@engineering11/customer-web'
import { FilesModule } from '@engineering11/files-web'
import { RegistrationModule } from '@engineering11/registration-web'
import { WebUtilityModule } from '@engineering11/web-utilities'
import { LetDirective } from '@ngrx/component'
import { EmployerCustomerConfigProvider, EmployerCustomerPartitionConfigProvider } from '../../config/customer.config'
import { UiComponentLibraryModule } from '../_component-library/component-library.module'
import { SharedModule } from '../_shared/shared.module'
import { InterviewQuestionLibraryModule } from '../interview-question-library/interview-question-library.module'
import { CompanyLogoUploadModalComponent } from './components/company-logo-upload-modal/company-logo-upload-modal.component'
import { CustomerManageHeaderComponent } from './components/customer-manage-header.component'
import { CustomerManageIndustryComponent } from './components/manage-industry/manage-industry.component'
import { CustomerManageProfilePicComponent } from './components/manage-profile-pic/manage-profile-pic.component'
import { CustomerManageSettingsComponent } from './components/manage-settings/manage-settings.component'
import { CustomerMenuLeftComponent } from './components/menu-left/menu-left.component'
import { CustomerModalProfilePicComponent } from './components/modal-profile-pic/modal-profile-pic.component'
import { CustomerRoutingModule } from './customer.routing'
import { CareersPageHeroComponent } from './views/careers-page-hero/careers-page-hero.component'
import { CustomerComplianceComponent } from './views/compliance.component'
import { CustomerDepartmentsComponent } from './views/departments.component'
import { CustomerHomeComponent } from './views/home/customer-home.component'
import { CustomerLocationsComponent } from './views/locations.component'
import { ManageCompanyLogoComponent } from './views/manage-company-logo/manage-company-logo.component'
import { CareersPageLinkComponent } from './views/manage-employer-settings/careers-page-link-section.component'
import { EmployerSettingComponent } from './views/manage-employer-settings/employer-setting.component'
import { ManageEmployerSettingsComponent } from './views/manage-employer-settings/manage-employer.component'
import { CustomerSocialMediaComponent } from './views/social-media/social-media.component'

@NgModule({
  declarations: [
    CustomerHomeComponent,
    CustomerMenuLeftComponent,
    CustomerManageProfilePicComponent,
    CustomerManageSettingsComponent,
    ManageEmployerSettingsComponent,
    CustomerManageIndustryComponent,
    CustomerDepartmentsComponent,
    ManageCompanyLogoComponent,
    CustomerModalProfilePicComponent,
    CareersPageHeroComponent,

    CompanyLogoUploadModalComponent,
    CustomerSocialMediaComponent,
    CustomerManageHeaderComponent,
    CustomerLocationsComponent,
    CustomerComplianceComponent,
    CareersPageLinkComponent,
    EmployerSettingComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SDKCustomerModule.forRoot({
      configProvider: EmployerCustomerConfigProvider,
      partitionConfigProvider: EmployerCustomerPartitionConfigProvider,
    }),
    CustomerRoutingModule,
    UiComponentLibraryModule,
    FormsModule,
    InterviewQuestionLibraryModule,
    ReactiveFormsModule,
    RegistrationModule,
    WebUtilityModule,
    LetDirective,
    FilesModule,
  ],
  exports: [],
  providers: [],
})
export class CustomerModule {}
