import { Component } from '@angular/core'
import { ConversationTypes, IEmployerConversationVM } from 'shared-lib'
import { IConversationVM, openConversationFilter } from '@engineering11/messaging-web'

@Component({
  selector: 'nav-item-direct-messages',
  templateUrl: './direct-messages-nav.component.html',
  styleUrls: ['./direct-messages-nav.component.scss'],
})
export class DirectMessagesNavComponent {
  ConversationTypes = ConversationTypes
  groupFn: (obj: IConversationVM) => string = (obj: IEmployerConversationVM) =>
    obj.clientMetadata?.jobPostId ?? obj.clientMetadata?.jobPostName ?? obj.shortName // TODO: Address missing value
  groupNameFn: (obj: IConversationVM) => string = (obj: IEmployerConversationVM) => obj.clientMetadata?.jobPostName ?? obj.shortName // TODO: Address missing value
  openConversationFilter = openConversationFilter
}
