<ng-container *ngrxLet="user$ as user">
  <form class="" [formGroup]="questionForm" (ngSubmit)="onSubmitFormQuestion(user)" novalidate>
    <div class="e11-mb-6 e11-border-b e11-border-skin-app-borders">
      <div class="e11-flex e11-justify-between">
        <div>
          <h3 class="e11-mb-4">{{ 'Your Question' | translate }}</h3>
        </div>
        <div class="e11-mb-2 e11-justify-end">
          <e11-button [buttonGroup]="true" [color]="'neutral'" (click)="cancelForm()" [ghost]="true" value="{{ 'Cancel' | translate }}"></e11-button>
          <e11-button
            *ngIf="selectedQuestion"
            [buttonGroup]="true"
            [color]="'secondary'"
            (click)="deleteQuestion()"
            value="{{ 'Delete' | translate }}"
          ></e11-button>
          <e11-button [type]="'submit'" [color]="'primary-accent'" value="{{ 'Save' | translate }}"></e11-button>
        </div>
      </div>
      <!-- Temporary flexed question and radios for MVP w/o tags + categories -->
      <div class="e11-flex e11-justify-between e11-mb-4">
        <div class="e11-grow e11-mr-4">
          <e11-textarea
            name="question"
            [required]="true"
            tabindex="0"
            label="{{ 'Question' | translate }}"
            placeholder="{{ 'Your Question Here' | translate }}"
            [parentForm]="questionForm"
            formControlName="question"
          >
            <div class="e11-w-full e11-flex e11-items-center">
              <div class="e11-grow">
                <e11-input-errors
                  *ngIf="questionFormSubmitted"
                  [parentForm]="questionForm"
                  [formControl]="f.question"
                  label="{{ 'Question' | translate }}"
                ></e11-input-errors>
              </div>
              <div class="e11-flex e11-justify-end">
                <span class="e11-text-xs">{{ f.question.value?.length }} / {{ maxQuestionLength }}</span>
              </div>
            </div>
          </e11-textarea>
        </div>
        <div class="e11-items-center e11-flex">
          <div class="e11-pb-4">
            <p class="e11-font-primary-demibold e11-text-sm e11-mb-2">{{ 'Response Type' | translate }}</p>
            <e11-radio-input
              label="{{ 'Text' | translate }}"
              radioValue="text"
              groupName="inputType"
              [value]="f.inputType.value === 'text'"
              [displayInline]="true"
              (valueChange)="f.inputType.setValue('text')"
            >
            </e11-radio-input>
            <e11-radio-input
              label="{{ 'Video' | translate }}"
              radioValue="video"
              groupName="inputType"
              [value]="f.inputType.value === 'video'"
              [displayInline]="true"
              (valueChange)="f.inputType.setValue('video')"
            >
            </e11-radio-input>
          </div>
        </div>
      </div>

      <!-- Below is implementation for correct styling when tags and categories are implemented -->
      <!-- <div class="md:e11-flex-col e11-flex e11-justify-between e11-grow e11-mt-8 e11-mb-4">
        <div class="e11-w-1/3 md:e11-w-full">
          <ui-interview-question-categories [categoryList]="f.categories.value || []"></ui-interview-question-categories>
        </div>
        <div class="e11-mx-16 md:e11-my-12">
          <p class="e11-font-primary-demibold e11-text-sm e11-mb-2">Response Type</p>
          <e11-radio-input
            label="{{ 'Text' | translate }}"
            radioValue="text"
            groupName="inputType"
            [value]="f.inputType.value === 'text'"
            [displayInline]="true"
            (valueChange)="f.inputType.setValue('text')"
          >
          </e11-radio-input>
          <e11-radio-input
            label="{{ 'Video' | translate }}"
            radioValue="video"
            groupName="inputType"
            [value]="f.inputType.value === 'video'"
            [displayInline]="true"
            (valueChange)="f.inputType.setValue('video')"
          >
          </e11-radio-input>
        </div>
        <div class=""e11-w-1/3 md:e11-w-full"">
          <ui-interview-question-tags [tagList]="f.tags.value || []"></ui-interview-question-tags>
        </div>
      </div> -->
    </div>
  </form>
</ng-container>
